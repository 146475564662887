<template>
  <div class="list-user">
    <div class="list-user-table">
      <div class="mb-2 d-flex justify-content-between">
        <h2 class="w-75 font-title" style="align-self: center">ニュース管理</h2>
        <div class="w-25 py-3">
          <button
            v-on:click="gotoDashboard()"
            class="float-right button-back-dashboard"
          >
            TOPへ
          </button>
          <button
            v-on:click="createNew()"
            class="float-right button-create-user mr-2"
          >
            新規作成
          </button>
        </div>
      </div>
      <div class="row px-3 d-flex">
        <div class="row-input p-3">
          <label class="col-sm-5 font-weight-bold pl-0">ニュースタイトル</label>
          <input
            class="form-input col-sm-12"
            v-model="infoNew.title"
            style="height: 35px"
          />
        </div>
      </div>
      <div class="row px-3 d-flex">
        <label class="col-12 font-weight-bold pl-3">投稿日時</label>
        <div class="row-input px-3">
          <div class="fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="infoNew.fromTimePost"
              :config="optionsDate"
              autocomplete="off"
              style="margin-bottom: 1rem"
              placeholder="日付を選択"
            ></date-picker>
          </div>
        </div>
        <span class="custom-span" style="font-size: 20px">~</span>
        <div class="row-input px-3">
          <div class="fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="infoNew.toTimePost"
              :config="optionsDate"
              autocomplete="off"
              style="margin-bottom: 1rem"
              placeholder="日付を選択"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="row px-3 d-flex">
        <label class="col-12 font-weight-bold pl-3">最終更新日時</label>
        <div class="mb-3 row-input px-3">
          <div class="fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="infoNew.fromTimeEdit"
              :config="optionsDate"
              autocomplete="off"
              style="margin-bottom: 1rem"
              placeholder="日付を選択"
            ></date-picker>
          </div>
        </div>
        <span class="custom-span" style="font-size: 20px">~</span>
        <div class="row-input px-3">
          <div class="fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="infoNew.toTimeEdit"
              :config="optionsDate"
              autocomplete="off"
              style="margin-bottom: 1rem"
              placeholder="日付を選択"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button v-on:click.prevent="getListNews()" variant="info" class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div>
      <Tables
        :items="listNewAdmin.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
      >
        <template v-slot:page_content="{ item }">
          <td v-html="item.page_content"></td>
        </template>
        <template v-slot:id="{ index }">
          <td>
            {{
              (listNewAdmin.current_page - 1) * listNewAdmin.per_page +
              index +
              1
            }}
          </td>
        </template>
        <template v-slot:action="{ item, index }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <div class="w-5">
              <router-link
                :to="{
                  name: 'edit new cdea',
                  params: { id: item.id },
                  query: {
                    NO:
                      (listNewAdmin.current_page - 1) * listNewAdmin.per_page +
                      index +
                      1,
                  },
                }"
                class="d-flex flex-column align-items-center"
              >
                <CButton
                  class="mx-1"
                  style="width: 67px"
                  color="primary"
                  square
                  variant="outline"
                  size="sm"
                  v-b-modal.modal-comfirm
                >
                  編集
                </CButton>
              </router-link>
            </div>
            <div>
              <CButton
                class="mx-1"
                style="width: 67px"
                color="danger"
                square
                v-on:click="deleteNews(item.id)"
                variant="outline"
                size="sm"
              >
                削除
              </CButton>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listNewAdmin.total"
      >
        <p class="font-weight-bold">
          {{ listNewAdmin.total }}件中{{ listNewAdmin.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listNewAdmin.last_page"
      >
        <CPagination
          v-if="listNewAdmin.total"
          :activePage.sync="page"
          :pages="listNewAdmin.last_page"
          align="center"
        />
        <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-new" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>削除しますと復元できませんのでご注意ください</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            v-on:click="deleteNewAdmin(infoNew.id)"
            block
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-new')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "ListNew",
  data() {
    return {
      infoNew: {
        id: "",
        title: "",
        fromTimePost: "",
        toTimePost: "",
        fromTimeEdit: "",
        toTimeEdit: "",
      },
      fields: tableFields.NEW_ADMIN,
      listLimitRecordPage: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limit: 10,
      page: 1,
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
    };
  },
  components: {
    Tables,
  },
  created() {
    this.getListNews(1);
  },
  computed: {
    ...mapGetters(["listNewAdmin", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.$bvModal.hide("delete-modal-new");
        this.getListNews(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listNewAdmin() {
      this.page = this.listNewAdmin.current_page;
    },
    page() {
      this.getListNews(this.page);
    },
  },
  methods: {
    ...mapActions({
      getListNewAdmin: "getListNewAdmin",
      deleteNewAdmin: "deleteNewAdmin",
    }),
    createNew() {
      this.$router.push({
        name: "create new cdea",
        query: {
          total: this.listNewAdmin.total ? this.listNewAdmin.total + 1 : 1,
        },
      });
    },
    getListNews(page) {
      if (
        (this.infoNew.fromTimePost &&
          this.infoNew.toTimePost &&
          this.infoNew.fromTimePost > this.infoNew.toTimePost) ||
        (this.infoNew.fromTimeEdit &&
          this.infoNew.toTimeEdit &&
          this.infoNew.fromTimeEdit > this.infoNew.toTimeEdit)
      ) {
        this.$toasted.error("該当日時の検索はできません。");
      } else {
        if (page === undefined) {
          page = this.page;
        }
        const formData = {
          screen: "cdea",
          page: page,
          data: {
            news_title: this.infoNew.title,
            post_start_date: this.infoNew.fromTimePost,
            post_end_date: this.infoNew.toTimePost,
            update_start_date: this.infoNew.fromTimeEdit,
            update_end_date: this.infoNew.toTimeEdit,
            limit: this.limit,
          },
        };
        this.getListNewAdmin(formData);
      }
    },
    changeLimitPage() {
      this.getListNews(1);
    },
    gotoDashboard() {
      this.$router.push({ name: "dashboard cdea" });
    },
    resetFieldSearch() {
      this.infoNew.title = "";
      this.infoNew.fromTimePost = "";
      this.infoNew.toTimePost = "";
      this.infoNew.fromTimeEdit = "";
      this.infoNew.toTimeEdit = "";
      const formData = {
        screen: "cdea",
        page: 1,
        data: {
          news_title: this.infoNew.title,
          post_start_date: this.infoNew.fromTimePost,
          post_end_date: this.infoNew.toTimePost,
          update_start_date: this.infoNew.fromTimeEdit,
          update_end_date: this.infoNew.toTimeEdit,
          limit: this.limit,
        },
      };
      this.getListNewAdmin(formData);
    },
    deleteNews(id) {
      this.infoNew.id = id;
      this.$bvModal.show("delete-modal-new");
    },
  },
};
</script>
